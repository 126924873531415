export class OrgAttributeStats {
  id: number = 0;
  name: string = "";
  nameArabic: string = "";
  responsePercent: number = 0;
  score: number = 0;

  static getRegionStats(regionResponse): OrgAttributeStats[] {
    let regionStats: OrgAttributeStats[] = [];
    if (regionResponse != null && regionResponse.length > 0) {
      regionResponse.forEach((region) => {
        let orgAttribute = new OrgAttributeStats();
        orgAttribute.id = region.regionId;
        orgAttribute.name = region.name;
        orgAttribute.nameArabic = region.nameArabic;
        orgAttribute.responsePercent = region.responsePercent ? region.responsePercent : 0;
        orgAttribute.score = region.score ? region.score : 0;
        regionStats.push(orgAttribute);
      });
    }
    return regionStats;
  }

  static getExeDepStats(exeResponse): OrgAttributeStats[] {
    let exeStats: OrgAttributeStats[] = [];
    if (exeResponse != null && exeResponse.length > 0) {
      exeResponse.forEach((region) => {
        let orgAttribute = new OrgAttributeStats();
        orgAttribute.id = region.exeDepartementId;
        orgAttribute.name = region.name;
        orgAttribute.nameArabic = region.name_arabic;
        orgAttribute.responsePercent = region.responsePercent ? region.responsePercent : 0;
        orgAttribute.score = region.score ? region.score : 0;
        exeStats.push(orgAttribute);
      });
    }
    return exeStats;
  }

  static getSectorStats(sectorResponse): OrgAttributeStats[] {
    let sectorStats: OrgAttributeStats[] = [];
    if (sectorResponse != null && sectorResponse.length > 0) {
      sectorResponse.forEach((sector) => {
        let orgAttribute = new OrgAttributeStats();
        orgAttribute.id = sector.sectorId;
        orgAttribute.name = sector.name;
        orgAttribute.nameArabic = sector.name_arabic;
        orgAttribute.responsePercent = sector.responsePercent ? sector.responsePercent : 0;
        orgAttribute.score = sector.score ? sector.score : 0;
        sectorStats.push(orgAttribute);
      });
    }
    return sectorStats;
  }

  static getEmpRoleStats(empRoleResponse): OrgAttributeStats[] {
    let empRoleStats: OrgAttributeStats[] = [];
    if (empRoleResponse != null && empRoleResponse.length > 0) {
      empRoleResponse.forEach((empRole) => {
        let orgAttribute = new OrgAttributeStats();
        orgAttribute.id = empRole.roleId;
        orgAttribute.name = empRole.roleName;
        orgAttribute.nameArabic = empRole.roleNameArabic;
        orgAttribute.responsePercent = empRole.responsePercent ? empRole.responsePercent : 0;
        orgAttribute.score = empRole.score ? empRole.score : 0;
        empRoleStats.push(orgAttribute);
      });
    }
    return empRoleStats;
  }

  static getGenderStats(genderResponse): OrgAttributeStats[] {
    let genderStats: OrgAttributeStats[] = [];
    if (genderResponse != null && genderResponse.length > 0) {
      genderResponse.forEach((gender) => {
        let orgAttribute = new OrgAttributeStats();
        orgAttribute.id = gender.genderId;
        if (gender.genderId == 1) {
          orgAttribute.name = "Male";
          orgAttribute.nameArabic = "ذكر";
        } else {
          orgAttribute.name = "Female";
          orgAttribute.nameArabic = "أنثى";
        }
        orgAttribute.responsePercent = gender.responsePercent ? gender.responsePercent : 0;
        orgAttribute.score = gender.score ? gender.score : 0;
        genderStats.push(orgAttribute);
      });
    }
    return genderStats;
  }

  static getExperienceStats(experienceResponse): OrgAttributeStats[] {
    let experienceStats: OrgAttributeStats[] = [];
    if (experienceResponse != null && experienceResponse.length > 0) {
      experienceResponse.forEach((exp) => {
        let orgAttribute = new OrgAttributeStats();
        orgAttribute.id = exp.experienceId;
        orgAttribute.responsePercent = exp.responsePercent ? exp.responsePercent : 0;
        orgAttribute.score = exp.score ? exp.score : 0;

        switch (orgAttribute.id) {
          case 1:
            orgAttribute.name = "Less than 3 months";
            orgAttribute.nameArabic = "أقل من 3 شهور";
            break;
          case 2:
            orgAttribute.name = "3 to 6 months";
            orgAttribute.nameArabic = "من 3 إلى 6 أشهر";
            break;
          case 3:
            orgAttribute.name = "6 to 12 months";
            orgAttribute.nameArabic = "من 6 إلى 12 أشهر";
            break;
          case 4:
            orgAttribute.name = "1 to 2 years";
            orgAttribute.nameArabic = "من سنة إلى سنتين";
            break;
          case 5:
            orgAttribute.name = "2 to 4 years";
            orgAttribute.nameArabic = "من 2 إلى 4 سنوات";
            break;
          case 6:
            orgAttribute.name = "4 years and above";
            orgAttribute.nameArabic = "4 سنوات فما فوق";
            break;
          default:
            break;
        }
        experienceStats.push(orgAttribute);
      });
    }
    return experienceStats;
  }
}
