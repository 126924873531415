export class Age {
    birthConstantId: number;
    birthConstantName: string;
    birthConstantNameArabic: string;

    static getAgeList(filterData): Age[] {
        var empDepartments: Age[] = [];
        filterData.forEach(Data => {
            var empDepartment = new Age();        
            empDepartment.birthConstantId = Data.birthConstantId;
            empDepartment.birthConstantName = Data.birthConstantName;
            empDepartment.birthConstantNameArabic = Data.birthConstantNameArabic;

            empDepartments.push(empDepartment);          
        });   
        return empDepartments;
    }
}
