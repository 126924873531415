import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { AppConstants } from "../utils/app-constants";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  resalhttpOptions = {
    headers: new HttpHeaders({
      Authorization: "rsl_chnl_8833ae27aa4f4ac38f9cf317a0656782",
    }),
  };

  constructor(private _httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  getSurveyDashboardForSurvey(surveyId: string, orgId: string) {
    let reqParams = {
      orgId: orgId,
      surveyId: surveyId,
    };
    return this._httpClient.post<any>(
      AppConstants.surveyDashboardURL,
      reqParams
    );
  }

  userLogin(userName: string, password: string) {
    let params = {
      username: userName,
      password: password,
    };
    return this._httpClient.post(
      AppConstants.loginURL,
      params,
      this.httpOptions
    );
  }

  getOrgAdminDashboard(orgId: string) {
    let params = {
      orgId: orgId,
    };
    return this._httpClient.post(
      AppConstants.orgAdminDashboardURL,
      params,
      this.httpOptions
    );
  }

  getOrganisationList() {
    return this._httpClient.post<any>(
      AppConstants.getOrganisationListURL,
      this.httpOptions
    );
  }

  getICMStoreProductLandingContent(params: any) {
    return this._httpClient.post(
      AppConstants.getICMStoreProductLandingContentURL,
      params,
      this.httpOptions
    );
  }
  filterProduct() {
    let params = {
      priceRange: [],
      vendorId: [],
      category: [],
      subCategory: [],
      processingTime: [],
    };
    return this._httpClient.post(
      AppConstants.filterProductURL,
      params,
      this.httpOptions
    );
  }
  createNewSurvey(params: any) {
    return this._httpClient.post<any>(
      AppConstants.createNewSurveyURL,
      params,
      this.httpOptions
    );
  }
  updateSurveyEmailTemplateContent(params: any) {
    return this._httpClient.post<any>(
      AppConstants.updateSurveyEmailTemplateContentURL,
      params,
      this.httpOptions
    );
  }
  cloneExistingSurvey(params: any) {
    return this._httpClient.post<any>(
      AppConstants.cloneExistingSurveyURL,
      params,
      this.httpOptions
    );
  }

  createCustomSurvey(params: any) {
    return this._httpClient.post<any>(
      AppConstants.createCustomSurveyURL,
      params,
      this.httpOptions
    );
  }
  editCustomSurveyDetail(params: any) {
    return this._httpClient.post<any>(
      AppConstants.editCustomSurveyDetailURL,
      params,
      this.httpOptions
    );
  }
  getCustomSurveyDetails(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getCustomSurveyDetailsURL,
      params,
      this.httpOptions
    );
  }
  getQuestionList() {
    return this._httpClient.post<any>(
      AppConstants.getQuestionListURL,
      this.httpOptions
    );
  }

  getOrgSurveyDashboardDetails(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgSurveyDashboardDetailsURL,
      params,
      this.httpOptions
    );
  }
  getOrgNonOHBISurveyDashboardDetails(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgNonOHBISurveyDashboardDetailsURL,
      params,
      this.httpOptions
    );
  }
  getInformativequestion() {
    return this._httpClient.post<any>(
      AppConstants.getInformativequestionURL,
      this.httpOptions
    );
  }

  getSurveyList() {
    return this._httpClient.post<any>(
      AppConstants.getSurveyListURL,
      this.httpOptions
    );
  }

  getSectorList() {
    return this._httpClient.post<any>(
      AppConstants.getSectorListURL,
      this.httpOptions
    );
  }

  getEmpRoleList() {
    return this._httpClient.post<any>(
      AppConstants.getEmpRoleListURL,
      this.httpOptions
    );
  }

  getRegionList() {
    return this._httpClient.post<any>(
      AppConstants.getRegionListURL,
      this.httpOptions
    );
  }

  createNewSector(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateSectorURL,
      params,
      this.httpOptions
    );
  }

  deleteSector(params: any) {
    return this._httpClient.post<any>(
      AppConstants.deleteSectorURL,
      params,
      this.httpOptions
    );
  }

  createNewRole(params: any) {
    return this._httpClient.post<any>(
      AppConstants.createNewRoleURL,
      params,
      this.httpOptions
    );
  }

  deleteRole(params: any) {
    return this._httpClient.post<any>(
      AppConstants.deleteRoleURL,
      params,
      this.httpOptions
    );
  }

  createNewRegion(params: any) {
    return this._httpClient.post<any>(
      AppConstants.createNewRegionURL,
      params,
      this.httpOptions
    );
  }

  deleteRegion(params: any) {
    return this._httpClient.post<any>(
      AppConstants.deleteRegionURL,
      params,
      this.httpOptions
    );
  }

  createNewEmployee(params: any) {
    return this._httpClient.post<any>(
      AppConstants.createNewEmployeeURL,
      params,
      this.httpOptions
    );
  }

  getResalEGift(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getResalEGiftURL,
      params,
      this.resalhttpOptions
    );
  }

  getOrgDashboard() {
    return this._httpClient.post<any>(
      AppConstants.getOrgDashboardURL,
      this.httpOptions
    );
  }

  getOrgDashboardDetails() {
    return this._httpClient.post<any>(
      AppConstants.getOrgDashboardDetailsURL,
      this.httpOptions
    );
  }

  getOrgEmployeeList() {
    return this._httpClient.post<any>(
      AppConstants.getOrgEmployeeListURL,
      this.httpOptions
    );
  }

  createOrgEmployeeType(params: any) {
    return this._httpClient.post<any>(
      AppConstants.createOrgEmployeeTypeURL,
      params,
      this.httpOptions
    );
  }

  getOrgEmployeeTypeList() {
    return this._httpClient.post<any>(
      AppConstants.getOrgEmployeeTypeListURL,
      this.httpOptions
    );
  }

  getOrgEmployeeDetail(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgEmployeeDetailURL,
      params,
      this.httpOptions
    );
  }

  updateSurveyEmailTemplate(params: any) {
    return this._httpClient.post<any>(
      AppConstants.updateSurveyEmailTemplateURL,
      params,
      this.httpOptions
    );
  }

  activateOrInactivateOrgRegion(params: any) {
    return this._httpClient.post<any>(
      AppConstants.activateOrInactivateOrgRegionURL,
      params,
      this.httpOptions
    );
  }
  activateOrInactivateOrgSector(params: any) {
    return this._httpClient.post<any>(
      AppConstants.activateOrInactivateOrgSectorURL,
      params,
      this.httpOptions
    );
  }
  activateOrInactivateOrgEmployeeRole(params: any) {
    return this._httpClient.post<any>(
      AppConstants.activateOrInactivateOrgEmployeeRoleURL,
      params,
      this.httpOptions
    );
  }
  activateOrInactivateOrgEmployee(params: any) {
    return this._httpClient.post<any>(
      AppConstants.activateOrInactivateOrgEmployeeURL,
      params,
      this.httpOptions
    );
  }
  activateOrInactivateOrgEmployeeType(params: any) {
    return this._httpClient.post<any>(
      AppConstants.activateOrInactivateOrgEmployeeTypeURL,
      params,
      this.httpOptions
    );
  }

  sendSurveyToEmployees(params: any) {
    return this._httpClient.post<any>(
      AppConstants.sendSurveyToEmployeesURL,
      params,
      this.httpOptions
    );
  }

  sendTestSurvey(params: any) {
    return this._httpClient.post<any>(
      AppConstants.sendTestSurvey,
      params,
      this.httpOptions
    );
  }

  getOrgSurveyDetails(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgSurveyDetailsURL,
      params,
      this.httpOptions
    );
  }

  sendSurveyToEmployeesBySearch(params: any) {
    return this._httpClient.post<any>(
      AppConstants.sendSurveyToEmployeesBySearchURL,
      params,
      this.httpOptions
    );
  }

  searchOrgEmployee(params: any) {
    return this._httpClient.post<any>(
      AppConstants.searchOrgEmployeeURL,
      params,
      this.httpOptions
    );
  }

  //Task Planner

  deleteComment(params: any) {
    return this._httpClient.post<any>(
      AppConstants.deleteCommentURL,
      params,
      this.httpOptions
    );
  }

  deleteSubTask(params: any) {
    return this._httpClient.post<any>(
      AppConstants.deleteSubTaskURL,
      params,
      this.httpOptions
    );
  }

  deleteTask(params: any) {
    return this._httpClient.post<any>(
      AppConstants.deleteTaskURL,
      params,
      this.httpOptions
    );
  }

  deleteBucket(params: any) {
    return this._httpClient.post<any>(
      AppConstants.deleteBucketURL,
      params,
      this.httpOptions
    );
  }

  getSubTaskDetails(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getSubTaskDetailsURL,
      params,
      this.httpOptions
    );
  }

  getTaskDetails(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getTaskDetailsURL,
      params,
      this.httpOptions
    );
  }

  getBucketDetails(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getBucketDetailsURL,
      params,
      this.httpOptions
    );
  }

  getBucketListForOrganization(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrganisationTaskBucketListURL,
      params,
      this.httpOptions
    );
  }

  getOrganisationDashBoard(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrganisationDashBoardURL,
      params,
      this.httpOptions
    );
  }
  getTaskListForBucket(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getBucketTaskListURL,
      params,
      this.httpOptions
    );
  }

  addOrUpdateTaskComment(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateCommentTotTaskURL,
      params,
      this.httpOptions
    );
  }

  addOrUpdateSubtaskToTask(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateSubTaskToTaskURL,
      params,
      this.httpOptions
    );
  }

  addOrUpdateTaskToBucket(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateTaskToTaskBucketURL,
      params,
      this.httpOptions
    );
  }

  addUsersToTaskBucket(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateUsersToTaskBucketURL,
      params,
      this.httpOptions
    );
  }

  addOrTaskUpdateBucketToOrg(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateBucketURL,
      params,
      this.httpOptions
    );
  }

  addAttachmentToTask(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addTaskAttachmentURL,
      params,
      this.httpOptions
    );
  }

  getAttractionSurveyDashbord(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getAttractionSurveyDashbordURL,
      params,
      this.httpOptions
    );
  }
  sendNonOHBISurveyReminder(params: any) {
    return this._httpClient.post<any>(
      AppConstants.sendNonOHBISurveyReminderURL,
      params,
      this.httpOptions
    );
  }
  deleteOrgSurvey(params: any) {
    return this._httpClient.post<any>(
      AppConstants.deleteOrgSurveyURL,
      params,
      this.httpOptions
    );
  }

  getOnboarding1Dashbord(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOnboarding1DashbordURL,
      params,
      this.httpOptions
    );
  }

  getOnboarding2Dashbord(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOnboarding2DashbordURL,
      params,
      this.httpOptions
    );
  }

  getDevelopmentSurveyDashbord(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getDevelopmentSurveyDashbordURL,
      params,
      this.httpOptions
    );
  }

  getRetentionSurveyDashbord(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getRetentionSurveyDashbordURL,
      params,
      this.httpOptions
    );
  }

  getExitSurveyDashbord(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getExitSurveyDashbordURL,
      params,
      this.httpOptions
    );
  }

  getCustomSurveyDashbord(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getCustomSurveyDashbordURL,
      params,
      this.httpOptions
    );
  }

  getNonOHBISurveyStats(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getNonOHBISurveyStatsURL,
      params,
      this.httpOptions
    );
  }

  getOrgNonOHBISurveyQuestions(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgNonOHBISurveyQuestionsURL,
      params,
      this.httpOptions
    );
  }

  sendAttractionSurveyInvite(params: any) {
    return this._httpClient.post<any>(
      AppConstants.sendAttractionSurveyInviteURL,
      params,
      this.httpOptions
    );
  }

  createNonOHBISurvey(params: any) {
    return this._httpClient.post<any>(
      AppConstants.createNonOHBISurveyURL,
      params,
      this.httpOptions
    );
  }

  setAutoSurveyInvite(params: any) {
    return this._httpClient.post<any>(
      AppConstants.setAutoSurveyInviteURL,
      params,
      this.httpOptions
    );
  }

  sendNonOHBISurveyToEmployeesBySearch(params: any) {
    return this._httpClient.post<any>(
      AppConstants.sendNonOHBISurveyToEmployeesBySearchURL,
      params,
      this.httpOptions
    );
  }

  getRecentlyJoinedEmpList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getRecentlyJoinedEmpListURL,
      params,
      this.httpOptions
    );
  }

  sendOnboardSurveyInvite(params: any) {
    return this._httpClient.post<any>(
      AppConstants.sendOnboardSurveyInviteURL,
      params,
      this.httpOptions
    );
  }

  getOtherNonOHBISurveyList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOtherNonOHBISurveyListURL,
      params,
      this.httpOptions
    );
  }
  updateTotalOrderStatus(params: any) {
    return this._httpClient.post<any>(
      AppConstants.updateTotalOrderStatusURL,
      params,
      this.httpOptions
    );
  }
  sendNonOHBISurveyToEmployees(params: any) {
    return this._httpClient.post<any>(
      AppConstants.sendNonOHBISurveyToEmployeesURL,
      params,
      this.httpOptions
    );
  }
  sendSurveyThroughEmail(params: any) {
    return this._httpClient.post<any>(
      AppConstants.sendSurveyThroughEmailURL,
      params,
      this.httpOptions
    );
  }

  getOtherNonOHBISurveyDetails(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOtherNonOHBISurveyDetailsURL,
      params,
      this.httpOptions
    );
  }

  getAutoSurveyInviteList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getAutoSurveyInviteListURL,
      params,
      this.httpOptions
    );
  }

  getNonOHBISurveyList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getNonOHBISurveyListURL,
      params,
      this.httpOptions
    );
  }

  getBucketUserList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getBucketUserListURL,
      params,
      this.httpOptions
    );
  }

  updateTaskStatus(params: any) {
    return this._httpClient.post<any>(
      AppConstants.updateTaskStatusURL,
      params,
      this.httpOptions
    );
  }

  updateWebportalAccessForEmployee(params: any) {
    return this._httpClient.post<any>(
      AppConstants.updateEmpWebportalAccessURL,
      params,
      this.httpOptions
    );
  }

  updateSubTaskStatus(params: any) {
    return this._httpClient.post<any>(
      AppConstants.updateSubTaskStatusURL,
      params,
      this.httpOptions
    );
  }

  sendOrgSurveyRemainder(params: any) {
    return this._httpClient.post<any>(
      AppConstants.sendOrgSurveyRemainderURL,
      params,
      this.httpOptions
    );
  }

  uploadfileToServer(imageFile: File, surveyId: number) {
    console.log(imageFile,surveyId)
    let fileUploadUrl = AppConstants.uploadSurveyImageURL;
    const formData: FormData = new FormData();
    // console.log("imagefile",imageFile)
    formData.append("uploadedImage", imageFile, imageFile?.name);
    formData.append("fileName", imageFile?.name);
    formData.append("surveyId", surveyId.toString());
    return this._httpClient.post(fileUploadUrl, formData);
  }

  uploadImageToServer(imageFile: File, surveyId: number, typeId: number) {
    // console.log(imageFile,surveyId)
    let fileUploadUrl = AppConstants.uploadSurveyImageURL;
    const formData: FormData = new FormData();
    // console.log("imagefile",imageFile)
    formData.append("uploadedImage", imageFile, imageFile.name);
    formData.append("fileName", imageFile.name);
    formData.append("surveyId", surveyId.toString());
    formData.append("ImageUpdateType", typeId.toString());
    return this._httpClient.post(fileUploadUrl, formData);
  }

  //ICM Store
  createProductOrderForMultiEmp(params: any) {
    return this._httpClient.post<any>(
      AppConstants.createProductOrderForMultiEmpURL,
      params,
      this.httpOptions
    );
  }
  getOrgOrderList() {
    let params = {};
    return this._httpClient.post(
      AppConstants.getOrgOrdersListURL,
      params,
      this.httpOptions
    );
  }
  getOrgOrderDetails(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgOrderDetailsURL,
      params,
      this.httpOptions
    );
  }
  sendOrgGiftValueRequest(params: any) {
    return this._httpClient.post<any>(
      AppConstants.sendOrgGiftValueRequestURL,
      params,
      this.httpOptions
    );
  }
  updateOrgSettingS(params: any) {
    return this._httpClient.post<any>(
      AppConstants.updateOrgSettingsURL,
      params,
      this.httpOptions
    );
  }
  getOrgSettings(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgSettingsURL,
      params,
      this.httpOptions
    );
  }
  changePasswordOrg(params: any) {
    return this._httpClient.post<any>(
      AppConstants.changePasswordOrgURL,
      params,
      this.httpOptions
    );
  }
  getOrgGiftValueHistory({}) {
    return this._httpClient.post<any>(
      AppConstants.getOrgGiftValueHistoryURL,
      this.httpOptions
    );
  }
  // fileUpload(params: any) {
  //   return this._httpClient.post<any>(AppConstants.fileUploadURL, params, this.httpOptions);
  // }
  fileUpload(imageFile: File, fileUploadType: number, fileSourceId: string) {
    let fileUploadUrl = AppConstants.fileUploadURL;
    let uploadType = fileUploadType.toString();
    const formData: FormData = new FormData();
    formData.append("uploadedImage", imageFile, imageFile.name);
    formData.append("fileName", imageFile.name);
    formData.append("fileUploadType", uploadType);
    formData.append("fileSourceId", fileSourceId);
    return this._httpClient.post(fileUploadUrl, formData);
  }

  getSuggestion(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getSuggestionURL,
      params,
      this.httpOptions
    );
  }
  // uploadfileToServer(imageFile: File, surveyId: number) {
  //   console.log(imageFile,surveyId)
  //   let fileUploadUrl = AppConstants.uploadSurveyImageURL;
  //   const formData: FormData = new FormData();
  //   formData.append("uploadedImage", imageFile, imageFile.name);
  //   formData.append("fileName", imageFile.name);
  //   formData.append("surveyId", surveyId.toString());
  //   return this._httpClient.post(fileUploadUrl, formData);
  // }

  //Update Multi Employee Role Rank API
  updateMultiEmpRoleRank(params: any) {
    return this._httpClient.post<any>(
      AppConstants.updateMultiEmpRoleRankURL,
      params,
      this.httpOptions
    );
  }

  updateEmpSupervisor(params: any) {
    return this._httpClient.post<any>(
      AppConstants.updateEmpSupervisorURL,
      params,
      this.httpOptions
    );
  }

  //Get DepartmentList
  getDeptEmployeeList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getDeptEmployeeListURL,
      params,
      this.httpOptions
    );
  }

  //Get DepartmentList
  getSurveyCoverage(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getSurveyCoverageURL,
      params,
      this.httpOptions
    );
  }
  sendSurveyInvite(params: any) {
    return this._httpClient.post<any>(
      AppConstants.sendSurveyInviteURL,
      params,
      this.httpOptions
    );
  }
  get360SurveyInviteRecordList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.get360SurveyInviteRecordListURL,
      params,
      this.httpOptions
    );
  }
  send360SurveyInviteToEmp(params: any) {
    return this._httpClient.post<any>(
      AppConstants.send360SurveyInviteToEmpURL,
      params,
      this.httpOptions
    );
  }

  get360SurveyList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.get360SurveyListURL,
      params,
      this.httpOptions
    );
  }

  getrecalculateSurveyCoverageValue(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getrecalculateSurveyCoverageValueURL,
      params,
      this.httpOptions
    );
  }

  get360DegreeOrgDashboard(params: any) {
    return this._httpClient.post<any>(
      AppConstants.get360DegreeOrgDashboardURL,
      params,
      this.httpOptions
    );
  }

  getOrgSurveyInviteEmailTemplate(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgSurveyInviteEmailTemplateURL,
      params,
      this.httpOptions
    );
  }

  //Get DepartmentList
  getGoalPillerList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getGoalPillerListURL,
      params,
      this.httpOptions
    );
  }

  getGoalCategoryList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getGoalCategoryListURL,
      params,
      this.httpOptions
    );
  }

  getGoalSubCategoryList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getGoalSubCategoryListURL,
      params,
      this.httpOptions
    );
  }
  createGoalPillerList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.createGoalPillerListURL,
      params,
      this.httpOptions
    );
  }
  createGoalCategoryList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.createGoalCategoryListURL,
      params,
      this.httpOptions
    );
  }
  createGoalSubCategoryList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.createGoalSubCategoryListURL,
      params,
      this.httpOptions
    );
  }
  activateOrDeactivateGoalCategory(params: any) {
    return this._httpClient.post<any>(
      AppConstants.activateOrDeactivateGoalCategoryURL,
      params,
      this.httpOptions
    );
  }

  //Get DepartmentList
  getGoalList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getGoalListURL,
      params,
      this.httpOptions
    );
  }
  createGoalList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.createGoalListURL,
      params,
      this.httpOptions
    );
  }
  getOrgAnnualEvaluationList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgAnnualEvaluationListURL,
      params,
      this.httpOptions
    );
  }
  addOrUpdateOrgAnnualEvaluation(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateOrgAnnualEvaluationURL,
      params,
      this.httpOptions
    );
  }

  validateAutoSurveyInvite(params: any) {
    return this._httpClient.post<any>(
      AppConstants.validateAutoSurveyInviteURL,
      params,
      this.httpOptions
    );
  }
  // Org Strategic goal

  getOrgStrategicObjectivesList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgStrategicObjectivesListURL,
      params,
      this.httpOptions
    );
  }

  deleteOrgStrategicGoal(params: any) {
    return this._httpClient.post<any>(
      AppConstants.deleteOrgStrategicGoalURL,
      params,
      this.httpOptions
    );
  }

  addOrUpdateOrgStrategicObjectives(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateOrgStrategicObjectivesURL,
      params,
      this.httpOptions
    );
  }

  getOrgStartegicPillerList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgStartegicPillerListURL,
      params,
      this.httpOptions
    );
  }

  getOrgStrategicGoalList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgStrategicGoalListURL,
      params,
      this.httpOptions
    );
  }

  addOrUpdateOrgStrategicPiller(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateOrgStrategicPillerURL,
      params,
      this.httpOptions
    );
  }

  getOrgStrategicEvaluationList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgStrategicEvaluationListURL,
      params,
      this.httpOptions
    );
  }
  addOrUpdateOrgStrategicEvaluation(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateOrgStrategicEvaluationURL,
      params,
      this.httpOptions
    );
  }

  getOrgStrategicEvaluationDetail(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgStrategicEvaluationDetailURL,
      params,
      this.httpOptions
    );
  }

  initiateStrategicEmpGoal(params: any) {
    return this._httpClient.post<any>(
      AppConstants.initiateStrategicEmpGoalURL,
      params,
      this.httpOptions
    );
  }

  activeOrInactiveOrgStrategicGoal(params: any) {
    return this._httpClient.post<any>(
      AppConstants.activeOrInactiveOrgStrategicGoalURL,
      params,
      this.httpOptions
    );
  }

  addOrUpdateOrgStrategicGoal(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateOrgStrategicGoalURL,
      params,
      this.httpOptions
    );
  }

  getOrgStrategicGoalDashboard(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgStrategicGoalDashboardURL,
      params,
      this.httpOptions
    );
  }

  // Org Strategic goal

  getOrgPerformanceObjectivesList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgPerformanceObjectivesListURL,
      params,
      this.httpOptions
    );
  }

  getOrgPerformanceEvaluationDetail(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgPerformanceEvaluationDetailURL,
      params,
      this.httpOptions
    );
  }

  addOrUpdateOrgPerformanceObjectives(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateOrgPerformanceObjectivesURL,
      params,
      this.httpOptions
    );
  }

  getOrgPerformancePillerList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgPerformancePillerListURL,
      params,
      this.httpOptions
    );
  }

  addOrUpdateOrgPerformancePiller(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateOrgPerformancePillerURL,
      params,
      this.httpOptions
    );
  }

  getOrgPerformanceEvaluationList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgPerformanceEvaluationListURL,
      params,
      this.httpOptions
    );
  }

  addOrUpdateOrgPerformanceEvaluation(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateOrgPerformanceEvaluationURL,
      params,
      this.httpOptions
    );
  }

  getOrgPerformanceEvaluationResults(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgPerformanceEvaluationResultsURL,
      params,
      this.httpOptions
    );
  }

  getOrgPerformanceEvaluationForMilestone(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgPerformanceEvaluationForMilestoneURL,
      params,
      this.httpOptions
    );
  }


  getEmployeePerformanceForMilestone(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getEmployeePerformanceForMilestoneURL,
      params,
      this.httpOptions
    );
  }

  activeOrInactiveOrgPerformanceGoal(params: any) {
    return this._httpClient.post<any>(
      AppConstants.activeOrInactiveOrgPerformanceGoalURL,
      params,
      this.httpOptions
    );
  }

  getOrgPerformanceGoalList(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getOrgPerformanceGoalListURL,
      params,
      this.httpOptions
    );
  }

  addOrUpdateOrgPerformanceGoal(params: any) {
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateOrgPerformanceGoalURL,
      params,
      this.httpOptions
    );
  }
  deleteOrgPerformanceGoal(params: any) {
    return this._httpClient.post<any>(
      AppConstants.deleteOrgPerformanceGoalURL,
      params,
      this.httpOptions
    );
  }

  initiatePerformanceEmpGoal(params: any) {
    return this._httpClient.post<any>(
      AppConstants.initiatePerformanceEmpGoalURL,
      params,
      this.httpOptions
    );
  }

  getOrgFilterList() {
    return this._httpClient.post<any>(
      AppConstants.getOrgFilterListURL,
      this.httpOptions
    );
  }

  addOrUpdateOrgEmpPerformanceMultyGoal(params: any){
    return this._httpClient.post<any>(
      AppConstants.addOrUpdateOrgEmpPerformanceMultyGoalURL,params,
      this.httpOptions
    );
  }

  getEmpGroupGoalList(params: any){
    return this._httpClient.post<any>(
      AppConstants.getEmpGroupGoalListURL,params,
      this.httpOptions
    );
  }

  getScoreRecomendations(params: any) {
    return this._httpClient.post<any>(
      AppConstants.getScoreRecomendationsURL,
      params,
      this.httpOptions
    );
  }

  
  
}
