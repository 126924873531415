export class ExecutiveDepartment {
    exeDepartementId: number;
    name: string;
    name_arabic: string;

    static getExecutiveDepartment(filterData): ExecutiveDepartment[] {
        var empDepartments: ExecutiveDepartment[] = [];
        filterData.forEach(Data => {
            var empDepartment = new ExecutiveDepartment();        
            empDepartment.exeDepartementId = Data.exeDepartementId;
            empDepartment.name = Data.name;
            empDepartment.name_arabic = Data.name_arabic;

            empDepartments.push(empDepartment);          
        });   
        return empDepartments;
    }
}
